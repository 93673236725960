  <template>
  <div>
    <div>
      <card>
        <div class="row">
          <div class="col-lg-12">
            <h5 class="title m-0">รูปแบบการเล่น</h5>
            <h5 class="card-category d-flex justify-content-between">
              รูปแบบการเล่นหมายถึงลักษณะการเลือกฝ่ายเดิมพัน
            </h5>
            <div
              class="btn-group btn-group-toggle btn-block"
              data-toggle="buttons"
              v-for="(data, i) in json_setting[botType != 2 ? 0 : 1].bet_side"
              :key="i"
              :value="data.value"
                :data-tooltip="data.description!=null?true:false"
              :data-tooltip-message="data.description"
            >
              <label
                :class="[
                  'btn btn-md btn-custom w-100',
                  'btn-' + data.color,
                  data.value == setting.bet_side ? 'active' : '',
                ]"
              >
                <input
                  type="radio"
                  :value="data.value"
                  v-model="setting.bet_side"
                />
                {{
                  botType != 2 ? data.title[botType == 1 ? 0 : 1] : data.title
                }}
              </label>
            </div>
          </div>

          <div class="col-lg-6">
            <hr class="style1 mt-4" />
            <h5 class="title m-0">กำไรเป้าหมาย</h5>
            <h5 class="card-category">
              ข้อแนะนำการเลือกกำไรเป้าเริ่มต้นที่ 5-10% และเก็บเป็นรอบ
              หรือวันละครั้งเพื่อลงทุนระยะยาว
            </h5>
            <div class="row p-0">
              <div class="col-md-5 pr-md-1">
                <h5 class="card-category mb-2">จำนวนเงิน</h5>
                <base-input
                  type="number"
                  placeholder="จำนวนเงิน"
                  :value="setting.profit_threshold"
                  v-model="setting.profit_threshold"
                  @input="p_threshold_keyup(setting.init_wallet)"
                >
                </base-input>
              </div>
              <div class="col-md-5 pl-md-1">
                <h5 class="card-category mb-2">จำนวนเปอร์เซ็น</h5>
                <base-input
                  type="number"
                  placeholder="จำนวนเปอร์เซ็น"
                  :value="setting.profit_percent"
                  v-model="setting.profit_percent"
                  @input="p_percent_keyup(setting.init_wallet)"
                >
                </base-input>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <hr class="style1 mt-4" />
            <h5 class="title m-0">กำหนดขาดทุนไม่เกิน</h5>
            <h5 class="card-category">
              ข้อแนะนำ แนะนำให้ตั้ง 3 เท่าของกำไรที่เป้าหมาย <br />
              <br />
            </h5>

            <div class="row p-0">
              <div class="col-md-5 pr-md-1">
                <h5 class="card-category mb-2">จำนวนเงิน</h5>
                <base-input
                  type="number"
                  placeholder="จำนวนเงิน"
                  :value="setting.loss_threshold"
                  v-model="setting.loss_threshold"
                  @input="l_threshold_keyup(setting.init_wallet)"
                >
                </base-input>
              </div>
              <div class="col-md-5 pl-md-1">
                <h5 class="card-category mb-2">จำนวนเปอร์เซ็น</h5>
                <base-input
                  type="number"
                  placeholder="จำนวนเปอร์เซ็น"
                  :value="setting.loss_percent"
                  v-model="setting.loss_percent"
                  @input="l_percent_keyup(setting.init_wallet)"
                >
                </base-input>
              </div>
            </div>
          </div>

          <div class="col-lg-12" v-if="botType != 2 && botType != 3">
            <hr class="style1 mt-4" />
            <h5 class="title m-0">แทงเสมอ</h5>
            <h5 class="card-category">ใส่จำนวนเงิน ได้ตั้งแต่ 10-1,600</h5>
            <div class="row p-0">
              <div class="col-md-11 pr-md-1">
                <h5 class="card-category mb-2">จำนวนเงิน</h5>
                <base-input
                  type="number"
                  placeholder="จำนวนเงิน"
                  :value="setting.b_tie_val"
                  v-model="setting.b_tie_val"
                >
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <hr class="style1 mt-4" />

            <h5 class="title m-0">เลือกชิพเริ่มต้น</h5>
            <h5 class="card-category">
              ข้อแนะนำการเลือกชิพนั้นไม่ควรกำหนดชิพเริ่มต้นเกิน 1% ของเงินทุน
              เช่นทุน 5,000 บาท ควรเริ่มเล่น 50 บาทเป็นต้น 50
            </h5>
            <div>
              <label
                :class="[
                  'btn btn-md btn-primary btn-custom',
                  data.value == setting.init_bet ? 'active' : '',
                ]"
                v-for="(data, i) in json_setting[botType != 2 ? 0 : 1].init_bet"
                :key="i"
                :value="data.value"
              >
                <input
                  type="radio"
                  :value="data.value"
                  v-model="setting.init_bet"
                  @change="create_play_data(init_martingale)"
                />
                {{ data.value }}
              </label>
            </div>
            <h5 class="title m-0">กำหนดเอง</h5>
            <div class="row p-0">
              <div class="col-md-11 pr-md-1">
                <base-input
                  class="currency"
                  type="number"
                  placeholder="จำนวนเงิน"
                  :value="setting.init_bet"
                  v-model="setting.init_bet"
                >
                </base-input>
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <hr class="style1 mt-4" />

            <h5 class="title m-0">รูปแบบการเดินเงิน</h5>
            <h5 class="card-category">
              การเดินเงินเป็นการบริหารความเสี่ยงในการลงทุนเพื่อให้สามารถทำกำไรจนถึงเป้าหมายที่กำหนด
            </h5>

            <div
              class="btn-group btn-group-toggle"
              data-toggle="buttons"
              v-for="(data, i) in json_setting[botType != 2 ? 0 : 1]
                .money_system"
              :key="i"
              :value="data.value"
            >
              <label
                :class="[
                  'btn btn-md btn-primary btn-custom d-flex justify-content-between',
                  ,
                  data.value == setting.money_system ? 'active' : '',
                ]"
                :disabled="set_disabled(data)"
              >
                <input
                  type="radio"
                  :value="data.value"
                  @click="changeMoneySytem(data)"
                  @change="check_martin"
                />
                {{ data.title }}
              </label>
            </div>
            <div v-if="setting.money_system == 5 || setting.money_system == 6">
              <div class="row m-1" data-toggle="buttons">
                <label
                  :class="[
                    'btn btn-md btn-primary btn-custom',
                    data == init_martingale ? 'active' : '',
                  ]"
                  v-for="(data, i) in setting.money_system == 6
                    ? martingale.slice(1)
                    : martingale"
                  :key="i"
                  :value="data"
                  @change="create_play_data(data)"
                >
                  <input type="radio" :value="data" v-model="init_martingale" />
                  {{ data }}
                </label>
              </div>
              <div class="row mt-2">
                <div
                  class="col-md-5 pr-md-1"
                  v-for="(data, i) in init_martingale"
                  :key="i"
                  :value="data"
                >
                  <base-input
                    type="number"
                    :placeholder="`ไม้ที่ ${data}`"
                    v-model="setting.playData[i]"
                    min="50"
                    step="50"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12" v-if="botType === 2">
            <hr class="style1 mt-4" />

            <h5 class="title m-0">แทงศูนย์</h5>
            <h5 class="card-category">ใส่จำนวนเงิน ได้ตั้งแต่ 10-1,600</h5>
            <div class="row p-0">
              <div class="col-md-11 pr-md-1">
                <h5 class="card-category mb-2">จำนวนเงิน</h5>
                <base-input
                  type="number"
                  placeholder="จำนวนเงิน"
                  :value="setting.zero_bet"
                  v-model="setting.zero_bet"
                >
                </base-input>
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <hr class="style1 mt-4" />

            <h5 class="title m-0">ถอนกำไรเข้ากระเป๋ากำไรและเริ่มเล่นใหม่</h5>
            <h5 class="card-category"></h5>
            <label class="switch">
              <input
                type="checkbox"
                :value="setting.is_infinite"
                v-model="setting.is_infinite"
              />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="col-lg-12">
            <hr class="style1 mt-4" />

            <h5 class="title m-0">ลิมิตการเล่น</h5>
            <h5 class="card-category"></h5>

            <div
              class="m-1 d-flex justify-content-between"
              data-toggle="buttons"
            >
              <label
                :class="[
                  'btn btn-md btn-primary btn-custom ',
                  data.value == setting.maximum_bet ? 'active' : '',
                ]"
                v-for="(data, i) in json_setting[0].maximum_bet"
                :key="i"
                :value="data.value"
              >
                <input
                  type="radio"
                  :value="data.value"
                  v-model="setting.maximum_bet"
                />
                {{ data.title }}
              </label>
            </div>
          </div>

          <div class="col-lg-6"></div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import Result from "./Result";
import Json_setting from "@/json/bot_setting.json";

import PopupDialog from "@/components/PopupDialog";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  components: { Result, PopupDialog, PulseLoader },
  computed: {
    set_disabled() {
      return (salut) =>
        salut.rules != null
          ? salut.rules != this.setting.bet_side
            ? true
            : false
          : false;
    },
  },
  props: {
    setting: Object,
    botType: { type: Number, default: 1 },
  },
  mounted() {
    for (var i = 1; i <= 18; i++) this.martingale.push(i);
  },

  data() {
    return {
      errorMsg: "",
      json_setting: Json_setting,
      martingale: [],
      init_martingale: 1,
      _wallet: 0,
    };
  },
  methods: {
    comma(Num) {
      Num += "";
      Num = Num.replace(/,/g, "");

      x = Num.split(".");
      x1 = x[0];
      x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) x1 = x1.replace(rgx, "$1" + "," + "$2");
      return x1 + x2;
    },
    changeMoneySytem(val) {
      if (val.rules === undefined) this.setting.money_system = val.value;
      else {
        if (this.setting.bet_side === val.rules)
          this.setting.money_system = val.value;
      }
    },

    p_threshold_keyup(data) {
      this.setting.profit_percent = (
        (this.setting.profit_threshold * 100) /
        data
      ).toFixed(2);
    },
    p_percent_keyup(data) {
      this.setting.profit_threshold =
        (data * this.setting.profit_percent) / 100;
    },
    l_threshold_keyup(data) {
      this.setting.loss_percent = (
        (this.setting.loss_threshold * 100) /
        data
      ).toFixed(2);
    },
    l_percent_keyup(data) {
      this.setting.loss_threshold = (data * this.setting.loss_percent) / 100;
    },
    check_martin() {
      if (this.setting.money_system === 6) {
        this.init_martingale = 2;
        this.create_play_data(2);
      } else {
        this.init_martingale = 1;
        this.create_play_data(1);
      }
    },
    create_play_data(value) {
      this.setting.playData = new Array(value)
        .fill(0)
        .map((x, idx) => this.setting.init_bet * 2 ** idx);
    },
  },
  beforeDestroy() {},
};
</script>

<style>
[data-tooltip] {
  position: relative;
  z-index: 10;
}

[data-tooltip]:before,
[data-tooltip]:after {
  display: none;
  position: absolute;
  top: 60px;
}

[data-tooltip]:before {
  border-top: .6em solid #303030;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  left: 20px;
  top: 40px;
  margin-bottom: 1em;
}

[data-tooltip]:after {
  background-color: #303030;
  border: 4px solid #303030;
  border-radius: 7px;
  color: #ffffff;
  content: attr(data-tooltip-message);
  left: 0;
  margin-bottom: 1.5em;
  padding: 5px 15px;
  white-space: pre-wrap;
  width: 200px;
}

[data-tooltip]:hover:after,
[data-tooltip]:hover:before {
  display: block;
}
.title{
  color:white !important;
}
.card-category{
  color:rgb(181, 181, 181) !important;
}
.style1 {
  background-color: white;
  opacity: 0.2;
}

.btn-custom {
  border: 2px;
  border-color: rgb(94, 94, 94) !important;
  color: white !important;
  box-shadow: none;
  padding: 14px;
  background: rgb(172, 172, 172) !important;
  min-width: 12%;
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 576px) {
}
</style>