<template>
  <div>
    <div class="row">
      <div class="col-lg-4">
        <card type="chart" class="bg-danger p-3">
          <template slot="header">
            <h5 class="card-category" style="color: white; text-align: center">
              {{ jsonIndex != 0 ? "ผล" : botType == 1 ? "Banker" : "Tiger" }}
            </h5>
            <h2 class="card-title text-white">
              <div class="winer-tag text-white" v-if="winner_banker == true">
                Win
              </div>
              <div style="text-align: center">
                {{ banker != null ? banker : "รอผล..." }}
              </div>
            </h2>
          </template>
        </card>
      </div>

      <div class="col-lg-4">
        <card type="chart" class="p-3">
          <template slot="header">
            <h5 class="card-category" style="text-align: center">
              นับเวลาถอยหลัง
            </h5>
            <h2 class="card-title text-white">
              <div style="text-align: center">
                {{ timer }}
                <pulse-loader
                  v-if="timer == null"
                  :color="color"
                  :size="size"
                ></pulse-loader>
              </div>
            </h2>
          </template>
        </card>
      </div>
      <div class="col-lg-4" v-if="jsonIndex != 1">
        <card type="chart" class="bg-primary p-3">
          <template slot="header">
            <h5
              class="card-category"
              style="text-align: left; color: white; text-align: center"
            >
              {{ botType == 1 ? "Player" : "Dragon" }}
            </h5>
            <h2 class="card-title text-white">
              <div class="winer-tag" v-if="winner_player == true">Win</div>
              <div style="text-align: center">
                {{ player != null ? player : "รอผล..." }}
              </div>
            </h2>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import ApexchartModel from "@/views/room/chart/ApexchartModel";

import TaskList from "@/pages/Dashboard/TaskList";

import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Json_setting from "@/json/bot_setting.json";
export default {
  components: {
    LineChart,
    BarChart,
    TaskList,
    PulseLoader,
    ApexchartModel,
  },
  props: {
    botType: Number,
    jsonIndex: Number,
  },

  mounted() {
    this.sockets.subscribe(`start`, (data) => {
      if (this.json_table[this.botType - 1].indexOf(data.data.tableID) != -1) {
        if (this.jsonIndex === 0 || this.jsonIndex === 3) {
          this.result = null;
          this.player = null;
          this.banker = null;
          this.winner_player = false;
          this.winner_banker = false;
          this.timer = data.remaining;
        } else {
          this.result = null;
          this.banker = null;
          this.winner_player = false;
          this.timer = data.remaining;
        }
        this.countDownTimer();
      }
    });
    this.sockets.subscribe(`point`, (data) => {
      if (this.json_table[this.botType - 1].indexOf(data.data.tableID) != -1) {
        if (this.jsonIndex === 0 || this.jsonIndex === 3) {
          this.banker = data.data.bankerHandValue;
          this.player = data.data.playerHandValue;
          this.timer = 0;
          if (data.data.winner === 1) {
            this.result = "Banker";
            this.winner_player = false;
            this.winner_banker = true;
          }
          if (data.data.winner === 2) {
            this.result = "Player";
            this.winner_player = true;
            this.winner_banker = false;
          }
          if (data.data.winner === 3) this.result = "Tie";
          if (data.data.winner === -1) this.result = null;
        } else {
          this.banker = `แต้ม ${data.score} ${data.result}`;
          this.timer = 0;
        }
      }
    });
  },
  data() {
    return {
      json_table: Json_setting[this.jsonIndex].table,
      winner_player: false,
      winner_banker: false,
      loading: true,
      color: "#fff",
      size: "6px",
      player: null,
      banker: null,
      result: null,
      timer: null,
      chartPointer: 0,
      tableId: "",
    };
  },
  computed: {},
  methods: {
    countDownTimer() {
      if (this.timer > 0) {
        setTimeout(() => {
          this.timer -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
  },
};
</script>
<style>
</style>
