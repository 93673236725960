<template>
  <div>
    <button
      type="submit"
      :class="[
        'btn btn-primary w-100 btn-lg',
        'text-white',
        botType === 1 ? 'btn-info' : 'btn-primary',
      ]"
      @click="next(1)"
    >
      <box-icon :size="'sm'" image="play-alt.svg" class="mr-2" />
      Baccarat
    </button>
    <button
      type="submit"
      :class="[
        'btn btn-primary w-100 btn-lg',
        'text-white',
        botType === 2 ? 'btn-info' : 'btn-primary',
      ]"
      @click="next(2)"
    >
      <box-icon :size="'sm'" image="play-alt.svg" class="mr-2" />
      Roulette
    </button>
    <button
      type="submit"
      :class="[
        'btn btn-primary w-100 btn-lg',
        'text-white',
        botType === 3 ? 'btn-info' : 'btn-primary',
      ]"
      @click="next(3)"
    >
      <box-icon :size="'sm'" image="play-alt.svg" class="mr-2" />
      Tiger Dragon
    </button>
  </div>
</template>
<script>
import BoxIcon from "@/components/Cards/BoxIcon";
export default {
  props: {
    is_loading: Boolean,
    wallet: Object,
    botType: Number,
  },
  components: {
    BoxIcon,
  },
  mounted() {},
  created() {},

  data() {
    return {};
  },
  computed: {},
  methods: {
    next(botType) {
      if (this.wallet != null)
        this.$router
          .push({
            path: `/room/${botType}`,
            params: {
              wallet: this.wallet.data,
            },
          })
          .catch(() => {});
      else {
        // this.countDownTimer();
        this.is_loading = true;
      }
    },
  },
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>
