var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{class:[
      'btn btn-primary w-100 btn-lg',
      'text-white',
      _vm.botType === 1 ? 'btn-info' : 'btn-primary' ],attrs:{"type":"submit"},on:{"click":function($event){return _vm.next(1)}}},[_c('box-icon',{staticClass:"mr-2",attrs:{"size":'sm',"image":"play-alt.svg"}}),_vm._v(" Baccarat ")],1),_c('button',{class:[
      'btn btn-primary w-100 btn-lg',
      'text-white',
      _vm.botType === 2 ? 'btn-info' : 'btn-primary' ],attrs:{"type":"submit"},on:{"click":function($event){return _vm.next(2)}}},[_c('box-icon',{staticClass:"mr-2",attrs:{"size":'sm',"image":"play-alt.svg"}}),_vm._v(" Roulette ")],1),_c('button',{class:[
      'btn btn-primary w-100 btn-lg',
      'text-white',
      _vm.botType === 3 ? 'btn-info' : 'btn-primary' ],attrs:{"type":"submit"},on:{"click":function($event){return _vm.next(3)}}},[_c('box-icon',{staticClass:"mr-2",attrs:{"size":'sm',"image":"play-alt.svg"}}),_vm._v(" Tiger Dragon ")],1)])}
var staticRenderFns = []

export { render, staticRenderFns }