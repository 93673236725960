<template>
  <div>
 <b-button v-b-toggle.collapse-1 variant="primary " class="btn btn-lg btn-primary collapsed"/>
  <b-collapse id="collapse-1" class="mt-2 collapse">
    <b-card>  
        
        <div class="row">
        <div class="col-lg-4">
       
          
          <h5 class="mt-2 text-white"><b>กราฟรวม Baccarat</b></h5>
       
 <apexchart-model
                :botId="null"
                :botType="1"
                :betSide="11"
                :setting_data="null"
                :smSize="true"
                :jsonIndex="0"
                :chartSize="[100, 310]"
              />

          <p></p>
       
      </div>
        <div class="col-lg-4">
           
              <h5 class="mt-2 text-white"><b>กราฟรวม Roulette</b></h5>
                 <apexchart-model
            :botId="null"
            :botType="2"
            :setting_data="null"
            :smSize="true"
            :jsonIndex="1"
            :chartSize="[100, 310]"
          />
             
              <p></p>
       
          </div>
      <div class="col-lg-4">
            
              <h5 class="mt-2 text-white"><b>กราฟรวม Tiger Dragon</b></h5>

              <apexchart-model
                :botId="null"
                :botType="3"
                :setting_data="null"
                :smSize="true"
                :jsonIndex="0"
                :chartSize="[100, 310]"
              />

              <p></p>
           
        
        </div></div>
    </b-card>
  </b-collapse>
  </div>
</template>
<script>

import ApexchartModel from "@/views/room/chart/ApexchartModel";


export default {
  components: {
  
    ApexchartModel,
  
  },
  props: {
   
  },

  mounted() {
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
 
};
</script>
<style scoped>
.btn:after {
    content: "ซ่อนกราฟทั้งหมด";
  }
  /* Icon when the collapsible content is hidden */
  .btn.collapsed:after {
    content: "ดูกราฟทั้งหมด";
  }
</style>
