  <template>
  <div>
    <popup-dialog @destroy_bot="destroy_bot" v-if="is_stop" />

    <setting v-if="!bot_created" :botType="botType" :setting="setting" />

    <div v-if="bot_created">
      <result
        ref="result"
        :setting="setting"
        :botId="botId"
        :bot_status="bot_status"
        :botType="botType"
        :wallet="setting.init_wallet"
        @destroy_bot="destroy_bot"
        @updateWallet="updateWallet"
        @callback_wallet="callback_wallet"
        @callback_connect="callback_connect"
        @isStop="isStop()"
      />
    </div>

    <wallet
      :botType="botType"
      :setting="setting"
      :bot_created="bot_created"
      :bot_status="bot_status"
      :bot_reverse="bot_reverse"
      :connect="connect"
      :open_zero="open_zero"
      :open_tie="open_tie"
      @create_bot="create_bot"
      @destroy_bot="destroy_bot"
      @set_opposite="set_opposite"
      @set_zero="set_zero"
      @set_tie="set_tie"
      @set_bot_status="set_bot_status"
    />
  </div>
</template>
<script>
import ProfitNotify from "@/pages/Notifications/ProfitNotification";
import LossNotify from "@/pages/Notifications/LossNotification";
import MaxInit from "@/pages/Notifications/InitNotification";
import WalletLess from "@/pages/Notifications/WalletLessNotificaion";
import BotPause from "@/pages/Notifications/BotPause";
import PopupDialog from "@/components/PopupDialog";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Wallet from "./component/Wallet.vue";
import Setting from "./component/Setting.vue";
import Result from "./component/Result.vue";
export default {
  components: { Result, PopupDialog, PulseLoader, Wallet, Setting, WalletLess },
  computed: {
    fullName() {
      return (salut) => `${salut} `;
    },
    set_disabled() {
      return (salut) =>
        salut.rules != null
          ? salut.rules != this.setting.bet_side
            ? true
            : false
          : false;
    },
    isTrial() {
      return localStorage.getItem("trial_active") != "false" ? true : false;
    },
  },
  props: {
    wallet: String || Number,
    setting_data: Object,
    setting_status: Boolean,
    botId: Number,
    botType: { type: Number, default: 1 },
  },
  mounted() {
    if (this.botType === 2) this.setting.bet_side = 11;
    this.setting.bot_type = this.botType;

    if (this.setting_status) {
      this.to_bot(this.setting_data);
    }
    this.setting.init_wallet = this.wallet;
    this.bot_status = this.setting_data.status == 2 ? false : true;
    this.bot_reverse = this.setting_data.is_opposite;
    this.open_zero = this.setting_data.open_zero;
    this.open_tie = this.setting_data.open_tie;
  },

  data() {
    return {
      errorMsg: "",
      transfer: "",
      hidden: false,
      is_stop: false,

      bot_created: false,

      _wallet: 0,
      setting: {
        deposite_count: 0,
        profit_wallet: 0,
        status: 1,
        bot_type: 1,
        money_system: 1,
        profit_threshold: 0,
        loss_threshold: 0,
        profit_percent: 0,
        loss_percent: 0,
        init_bet: 10,
        bet_side: 1,
        is_infinite: false,
        playData: new Array(1).fill(0).map((x, idx) => 50 * 2 ** idx),
        username: this.$store.getters.getDisplayName,
        init_wallet: 0,
        zero_bet: 0,
        open_zero: false,
        b_tie_val: 0,

        bet_limit: "99999",
        maximum_bet: 2000,
      },
      notifications: {
        topCenter: false,
      },
      bot_status: false,
      bot_reverse: false,
      open_zero: false,
      open_tie: false,
      connect: false,
      create_loading: false,
    };
  },
  methods: {
    changeMoneySytem(val) {
      if (val.rules === undefined) this.setting.money_system = val.value;
      else {
        if (this.setting.bet_side === val.rules)
          this.setting.money_system = val.value;
      }
    },

    p_threshold_keyup(data) {
      this.setting.profit_percent = (
        (this.setting.profit_threshold * 100) /
        data
      ).toFixed(2);
    },
    p_percent_keyup(data) {
      this.setting.profit_threshold =
        (data * this.setting.profit_percent) / 100;
    },
    l_threshold_keyup(data) {
      this.setting.loss_percent = (
        (this.setting.loss_threshold * 100) /
        data
      ).toFixed(2);
    },
    l_percent_keyup(data) {
      this.setting.loss_threshold = (data * this.setting.loss_percent) / 100;
    },

    create_play_data(value) {
      this.setting.playData = new Array(value)
        .fill(0)
        .map((x, idx) => this.setting.init_bet * 2 ** idx);
    },
    create_bot() {
      let data = this.setting;

      if (data.init_bet > 10000 || data.init_bet === "" || data.init_bet < 10) {
        this.notifyVue("top", "right", MaxInit, "danger");
      }
      // if (data.init_wallet < data.init_bet) {
      //   this.notifyVue("top", "right", WalletLess, "danger");
      // }
      else {
        if (
          data.profit_threshold === "" ||
          data.profit_threshold === 0 ||
          data.profit_percent === "" ||
          data.profit_percent === 0
        )
          this.notifyVue("top", "right", ProfitNotify, "danger");
        if (
          data.loss_threshold === "" ||
          data.loss_threshold === 0 ||
          data.loss_percent === "" ||
          data.loss_percent === 0
        )
          this.notifyVue("top", "right", LossNotify, "danger");
        if (data.loss_threshold != "" && data.loss_threshold != 0) {
          data.init_wallet = this.wallet;
          data.profit_threshold =
            parseFloat(this.wallet) + parseFloat(data.profit_threshold);
          data.loss_threshold =
            parseFloat(this.wallet) - parseFloat(data.loss_threshold);

          this.create_loading = true;
          this.$http
            .post(`${process.env.VUE_APP_BACKEND_API_URL}/bot`, data)
            .then(({ data }) => {
              this.setting = data.data;
              this.bot_status = false;
              this.bot_created = true;
              this.create_loading = false;

              this.$emit("create_bot", this.setting);
            })
            .catch((err) => {
              this.create_loading = false;
              this.errorMsg = err.response.data.message;
              return;
            });
        }
      }
    },
    to_bot(data) {
      this.setting = data;
      this.bot_created = true;
    },
    notifyVue(verticalAlign, horizontalAlign, component, type) {
      this.$notify({
        component: component,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: type,
        timeout: 3000,
      });
    },
    updateWallet(data) {
      console.log("update_wallet", data);
      this.setting.init_wallet = data.init_wallet;
      this.setting.deposite_count = data.deposite_count;
      this.setting.profit_wallet = data.profit_wallet;
    },

    pause_bot() {
      if (this.connect) {
        if (this.bot_status) {
          this.$http
            .post(`${process.env.VUE_APP_BACKEND_API_URL}/pause`, {
              username: this.$store.getters.getDisplayName,
            })
            .then(({}) => {
              this.bot_status = false;
            })
            .catch((err) => {
              this.errorMsg = err.response.data.message;
              return;
            });
        } else {
          this.$http
            .post(`${process.env.VUE_APP_BACKEND_API_URL}/start`, {
              username: this.$store.getters.getDisplayName,
            })
            .then(({}) => {
              this.bot_status = true;
            })
            .catch((err) => {
              this.errorMsg = err.response.data.message;
              return;
            });
        }
      }
    },
    set_tie() {
      if (!this.bot_status) {
        if (!this.open_tie) {
          this.$http
            .post(`${process.env.VUE_APP_BACKEND_API_URL}/bot/set_tie`, {
              username: this.$store.getters.getDisplayName,
              b_tie_val: this.setting_data.b_tie_val,
              b_tie: true,
            })
            .then(({}) => {
              this.open_tie = true;
            })
            .catch((err) => {
              return err;
            });
        } else {
          this.$http
            .post(`${process.env.VUE_APP_BACKEND_API_URL}/bot/set_tie`, {
              username: this.$store.getters.getDisplayName,
              b_tie_val: this.setting_data.b_tie_val,
              b_tie: false,
            })
            .then(({}) => {
              this.open_tie = false;
            })
            .catch((err) => {
              return err;
            });
        }
      }
    },
    set_zero() {
      if (!this.bot_status) {
        if (!this.open_zero) {
          this.$http
            .post(`${process.env.VUE_APP_BACKEND_API_URL}/bot/set_zero`, {
              username: this.$store.getters.getDisplayName,
              zero_bet: this.setting_data.zero_bet,
              open_zero: true,
            })
            .then(({}) => {
              this.open_zero = true;
            })
            .catch((err) => {
              return err;
            });
        } else {
          this.$http
            .post(`${process.env.VUE_APP_BACKEND_API_URL}/bot/set_zero`, {
              username: this.$store.getters.getDisplayName,
              zero_bet: this.setting_data.zero_bet,
              open_zero: false,
            })
            .then(({}) => {
              this.open_zero = false;
            })
            .catch((err) => {
              return err;
            });
        }
      }
    },
    set_opposite() {
      if (!this.bot_status) {
        if (!this.bot_reverse) {
          this.$http
            .post(`${process.env.VUE_APP_BACKEND_API_URL}/bot/set_opposite`, {
              username: this.$store.getters.getDisplayName,
              is_opposite: true,
            })
            .then(({}) => {
              this.bot_reverse = true;
              console.log("set_opposite", this.bot_reverse);
            })
            .catch((err) => {
              return err;
            });
        } else {
          this.$http
            .post(`${process.env.VUE_APP_BACKEND_API_URL}/bot/set_opposite`, {
              username: this.$store.getters.getDisplayName,
              is_opposite: false,
            })
            .then(({}) => {
              this.bot_reverse = false;
              console.log("set_opposite", this.bot_reverse);
            })
            .catch((err) => {
              return err;
            });
        }
      } else {
        this.notifyVue("top", "right", BotPause, "warning");
      }
    },
    destroy_bot() {
      this.$http
        .post(`${process.env.VUE_APP_BACKEND_API_URL}/stop`, {
          username: this.$store.getters.getDisplayName,
        })
        .then(() => {
          this.setting = {
            deposite_count: 0,
            profit_wallet: 0,
            status: 1,
            bot_type: 1,
            money_system: 1,
            profit_threshold: 0,
            loss_threshold: 0,
            profit_percent: 0,
            loss_percent: 0,
            init_bet: 50,
            bet_side: this.botType === 2 ? 11 : 1,
            zero_bet: 0,
            open_zero: false,
            is_infinite: false,
            playData: new Array(1).fill(0).map((x, idx) => 50 * 2 ** idx),
            username: this.$store.getters.getDisplayName,
            // bet_limit: "260901",
            bet_limit: "99999",
            maximum_bet: 2000,
          };
          this.setting.bot_type = this.botType;

          this.bot_created = false;
          this.is_stop = false;
          this.$refs.result.callback_wallet();
          console.log("destroy");
          window.location.reload();
        })
        .catch((err) => {
          return err;
        });
    },
    set_bot_status(data) {
      this.bot_status = data;
    },
    callback_wallet(data) {
      this.setting.init_wallet = data.init_wallet;
      this.setting.deposite_count = data.deposite_count;
      this.setting.profit_wallet = data.profit_wallet;
    },
    callback_connect(data) {
      this.connect = data;
    },
    hidden_() {
      this.hidden = true;
      setTimeout(() => {
        this.show_wallet = false;
      }, 1000);
    },

    isStop() {
      this.is_stop = true;
    },
  },
  beforeDestroy() {},
};
</script>
<style>
@media screen and (max-width: 991px) {
}

@media screen and (max-width: 576px) {
}
</style>